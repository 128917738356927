:root {
  --main-colour: #59546C;
  --second-colour: #8B939C;
  --third-colour: #0E131F;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: linear-gradient(90deg, #38405F 0%, #444D74 100%);
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

* {
  font-family: "Verdana", cursive, sans-serif;
  color: #ffffff;
  
}

.app {
  background-color: var(--main-colour);
  width: 75vw;
  height: 60vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* height: min-content; */
  border-radius: 15px;
  padding: 20px;
  box-shadow: 10px 10px 42px 0px rgba(0, 0, 0, 0.75);
  
}

.container {
  flex-direction: column;
  justify-content: space-evenly;
}


/* Scores view */

.score-section {
  margin-top: 20px;
  font-size: 4rem;
  align-items: baseline;
}

.score-details {
  font-size: 1.5rem;
  text-align: left;
  margin-top: 30px;
  align-items: baseline;
}

.bkDesc {
  margin-top: 30px;
  font-size: 1rem;
  width: 60vw;
  line-height: 125%;
  margin-bottom: 20px;
}

/* Question view */

.question-section {
  width: 100%;
  position: relative;
}

.question-count {
  margin-bottom: 20px;
}

.question-count span {
  font-size: 1rem;
}

.question-text {
  margin-top: 50px;
  margin-bottom: 12px;
  width: 90%;
  font-size: 3rem;
}

.answer-section {
  align-self: flex-end;
  width: 100%;
  height: 40%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.answer-button {
  height: 50%;
  padding: 20px
}

button {
  width: auto;
  height: 40px;
  font-size: 1.5rem;
  color: #ffffff;
  background-color: var(--second-colour);
  border-radius: 15px;
  justify-content: flex-start;
  text-align: left;
  align-items: center;
  border: 5px solid var(--third-colour);
  cursor: pointer;
  margin-top: 10px;
}

button:hover {
  background-color: var(--third-colour);
        }

button:focus {
  outline: none;
}

button svg {
  margin-right: 5px;
}

.share-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}


@media only screen and (max-width:1100px) {
  
  

  .app {
    width: 90vw;
    height: 85vh
  }
}
